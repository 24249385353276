// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qT1hZ1oLwu2pHsrw3zXm{display:flex;align-items:center;justify-content:center;height:100vh;overflow:hidden}.lXkyQbRBO0t9RdK3DZPT{display:flex;align-items:center;justify-content:space-between;flex-direction:column;min-height:calc(65vh - 70px);width:100%;padding-top:20%;box-sizing:border-box}.GGg5_rcQufirsXHM_ANE{font-size:45px}.FIbHpV4XrcOu4lfy1Q4M{width:100% !important;padding:0 15px;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/pages/login-page/login-page.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,eAAA,CACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,qBAAA,CACA,4BAAA,CACA,UAAA,CACA,eAAA,CACA,qBAAA,CAEJ,sBACI,cAAA,CAEJ,sBACI,qBAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".main {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    overflow: hidden;\n    &Inner {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        flex-direction: column;\n        min-height: calc(65vh - 70px);\n        width: 100%;\n        padding-top: 20%;\n        box-sizing: border-box;\n    }\n    &Logo {\n        font-size: 45px;\n    }\n    &Button {\n        width: 100% !important;\n        padding: 0 15px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `qT1hZ1oLwu2pHsrw3zXm`,
	"mainInner": `lXkyQbRBO0t9RdK3DZPT`,
	"mainLogo": `GGg5_rcQufirsXHM_ANE`,
	"mainButton": `FIbHpV4XrcOu4lfy1Q4M`
};
export default ___CSS_LOADER_EXPORT___;
