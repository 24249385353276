// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H24cb6XTa3UvaxFE5yCe{padding:.3125rem .625rem;border-radius:.625rem;background:#1c1c1c;display:flex;align-items:center;justify-content:space-between;margin-bottom:20px;transition:all .3s ease 0s}.H24cb6XTa3UvaxFE5yCe:hover{background:#2b2a2a}.T_FZ81ijcm3JM1ETj0eS{display:flex;align-items:center;gap:.9375rem}.mIvgyUYLH3uj16oCIu_p{font-size:1.125rem;word-wrap:break-word}`, "",{"version":3,"sources":["webpack://./src/components/link/settings-link.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,0BAAA,CACA,4BACI,kBAAA,CAEJ,sBACI,YAAA,CACA,kBAAA,CACA,YAAA,CAEJ,sBACI,kBAAA,CACA,oBAAA","sourcesContent":[".link {\n  padding: 0.3125rem 0.625rem;\n  border-radius: 0.625rem;\n  background: #1c1c1c;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  transition: all 0.3s ease 0s;\n  &:hover {\n      background: #2b2a2a;\n  }\n  &Body {\n      display: flex;\n      align-items: center;\n      gap: 0.9375rem;\n  }\n  &Title {\n      font-size: 1.125rem;\n      word-wrap: break-word;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `H24cb6XTa3UvaxFE5yCe`,
	"linkBody": `T_FZ81ijcm3JM1ETj0eS`,
	"linkTitle": `mIvgyUYLH3uj16oCIu_p`
};
export default ___CSS_LOADER_EXPORT___;
