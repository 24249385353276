import { beginCell, Cell, contractAddress, SendMode } from 'ton-core';
export function oneTimeChequeConfigToCell(config) {
    return beginCell()
        .storeBuffer(config.publicKey)
        .storeRef(config.claimCont)
        .storeUint(BigInt(Math.floor(Math.random() * 1e9)), 32)
        .endCell();
}
export const Opcodes = {
    claim: 0x79b0b258,
};
export const ClaimFunctions = {
    toncoin: Cell.fromBoc(Buffer.from('B5EE9C720101010100150000268010C8CB05CE70FA027001CB6AC9810080FB00', 'hex'))[0],
};
export class OneTimeCheque {
    constructor(address, init) {
        this.address = address;
        this.init = init;
    }
    static createFromAddress(address) {
        return new OneTimeCheque(address);
    }
    static createFromConfig(config, code, workchain = 0) {
        const data = oneTimeChequeConfigToCell(config);
        const init = { code, data };
        return new OneTimeCheque(contractAddress(workchain, init), init);
    }
    async sendDeploy(provider, via, value) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell().endCell(),
        });
    }
    async sendClaim(provider, opts) {
        await provider.external(beginCell().storeUint(Opcodes.claim, 32).storeBuffer(opts.signature).storeAddress(opts.address).endCell());
    }
}
