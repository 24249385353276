import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { useState, useEffect } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { toast } from 'react-toastify';
import { Button } from '../ui/button';
import { StorageWallet } from '../../logic/storage';
import { Coupon } from '../../logic/coupon';
import { fixAmount } from '../../utils/fix-amount';
import { smlAddr } from '../../utils/sml-addr';
import TokenPriceHook from '../../hooks/token-price-hook';
import { useQRCodeDownloader } from '../../hooks/use-qr-code-downloader';
import { useBgTelegram } from '../../hooks/useBgTelegram';
import { useTextTelegram } from '../../hooks/useTextTelegram';
import DONE from '../../assets/images/checks/done.svg';
import CANCEL from '../../assets/images/checks/cancel.svg';
import { SvgSelector } from '../../assets/icons/svg-selector';
import s from './multichecks.module.scss';
export const Multichecks = ({ selectedCheckCard, setSelectedCheckCard, isTestnet, isTg }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [bal, setBal] = useState('0');
    const [info, setInfo] = useState(null);
    const [usage, setUsage] = useState(0);
    const storageWallet = new StorageWallet();
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const cp = new Coupon(tonConnectUI, isTestnet);
    useEffect(() => {
        const getMultiData = () => {
            try {
                const result = storageWallet.get(selectedCheckCard?.id);
                if (result) {
                    setInfo(result[0]);
                }
            }
            catch (error) {
                console.error(error);
            }
        };
        getMultiData();
    }, [selectedCheckCard?.id]);
    useEffect(() => {
        const fetchCouponBalance = async () => {
            if (info && info.address) {
                try {
                    const bl = await Coupon.getSumCoupon(info.address, isTestnet);
                    setBal(bl);
                }
                catch (error) {
                    console.error('Error fetching coupon balance:', error);
                }
            }
        };
        async function fetchUsage() {
            if (!info?.address)
                return;
            try {
                const res = await cp.getSumActivation(info?.address);
                if (typeof res === 'number') {
                    setUsage(res);
                }
                else {
                    setUsage(0);
                }
            }
            catch (error) {
                console.error('Error fetching usage:', error);
            }
        }
        console.log('rerender');
        if (isVisible && info && selectedCheckCard?.id === info.id) {
            fetchCouponBalance();
            fetchUsage();
        }
    }, [info?.address, selectedCheckCard?.id, isVisible]);
    const handleCancelButtonClick = () => {
        setIsVisible(false);
        setSelectedCheckCard({ id: '', selected: '' });
        setBal('0');
        setInfo(null);
    };
    const handleCopyAddress = () => {
        if (!info) {
            console.error('Something went wrong');
            return;
        }
        if (!info.address) {
            console.error('Something went wrong');
            return;
        }
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = info.address;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);
        toast.success('Check has been copied to the clipboard');
    };
    const generateQRCodeAndDownload = useQRCodeDownloader(`${window.location.origin}/login?a=${info?.address}` ?? '');
    const handleShareAddress = () => {
        const copyableAddress = window.location.origin + `/login?a=${info?.address}`;
        navigator.clipboard.writeText(copyableAddress);
        toast.success('Check has been copied to the clipboard');
    };
    const handleRemove = async () => {
        const us = new Coupon(tonConnectUI, isTestnet);
        if (!info?.address)
            return console.error('Error');
        const userConfirmed = window.confirm('Вы уверены, что хотите удалить чек?');
        if (!userConfirmed) {
            return;
        }
        try {
            const res = await us.destroyMulti(info?.address);
            if (res === true) {
                handleCancelButtonClick();
                storageWallet.del(selectedCheckCard?.id);
                window.location.reload();
            }
            return res;
        }
        catch (error) {
            console.error('Error fetching usage:', error);
        }
    };
    const telegramBG = useBgTelegram(isTg);
    const telegramText = useTextTelegram(isTg);
    const [styleBtn, setStyleBtn] = useState({});
    useEffect(() => {
        if (isTg) {
            setStyleBtn({
                backgroundColor: 'var(--tg-theme-button-color)',
                color: 'var(--tg-theme-button-text-color)',
                important: 'true'
            });
        }
        else {
            setStyleBtn({});
        }
    }, [isTg]);
    return (_jsxs("div", { className: s.multicheckBody, children: [isVisible && _jsx("div", { className: s.overlay }), _jsx("div", { className: `${s.multicheck} ${isVisible ? s.slideIn : s.slideOut}`, children: _jsx("div", { className: s.multicheckInner, children: _jsxs("div", { className: `container ${s.container}`, style: telegramBG, children: [_jsxs("div", { className: s.headerTop, children: [_jsx("h1", { className: s.headerTitle, style: telegramText, children: "Multicheck" }), _jsx(Button, { variant: "small-button", startIcon: CANCEL, onClick: handleCancelButtonClick, style: styleBtn })] }), _jsxs("div", { className: `${s.multicheckInfo}`, style: telegramBG, children: [_jsxs("div", { className: s.item, children: [_jsx("div", { className: s.title, style: telegramText, children: "Status:" }), _jsx("div", { className: s.description, style: telegramText, children: Number(fixAmount(bal)) > 0.001 ? 'Not activated' : 'Activated' })] }), _jsxs("div", { className: s.item, children: [_jsx("div", { className: s.title, style: telegramText, children: "Address:" }), _jsx("div", { className: s.descriptionAddress, onClick: handleCopyAddress, style: telegramText, children: smlAddr(info?.address) })] }), _jsxs("div", { className: s.item, children: [_jsx("div", { className: s.title, style: telegramText, children: "Sum:" }), _jsxs("div", { className: s.description, style: telegramText, children: [fixAmount(Number(bal)), " TON (", _jsx(TokenPriceHook, { tokenAmount: Number(fixAmount(bal)) }), ")"] })] }), _jsxs("div", { className: s.item, children: [_jsx("div", { className: s.title, style: telegramText, children: "Amount of one activation:" }), _jsxs("div", { className: s.description, style: telegramText, children: [info?.amountActivation !== undefined
                                                        ? fixAmount(Number(bal) / Number(info?.amountActivation))
                                                        : 0 + ' ', "TON (", _jsx(TokenPriceHook, { tokenAmount: info?.amountActivation !== undefined
                                                            ? Number(fixAmount(Number(bal) / Number(info?.amountActivation)))
                                                            : 0 }), ")"] })] }), _jsxs("div", { className: s.item, children: [_jsx("div", { className: s.title, style: telegramText, children: "Number of activations:" }), _jsxs("div", { className: s.description, style: telegramText, children: [usage === 0 ? '0' : usage, " out of ", info?.amountActivation] })] }), _jsxs("div", { className: s.item, children: [_jsx("div", { className: s.title, style: telegramText, children: "Password:" }), _jsx("div", { className: s.status, children: _jsx("img", { src: DONE, alt: "Done" }) })] }), _jsxs("div", { className: s.itemAction, children: [_jsx("div", { className: s.titleDownload, style: telegramText, children: "Download:" }), _jsx("div", { children: _jsxs("button", { className: s.itemDownload, onClick: generateQRCodeAndDownload, style: styleBtn, children: ["Download", _jsx(SvgSelector, { id: "download", isTg: isTg })] }) })] }), _jsxs("div", { className: s.multicheckActions, children: [_jsx(Button, { variant: "action-button", startIcon: _jsx(SvgSelector, { id: "share_outline", isTg: isTg }), onClick: handleShareAddress, style: styleBtn, isTg: isTg, children: "Share" }), _jsx(Button, { variant: "action-button", startIcon: _jsx(SvgSelector, { id: "delete", isTg: isTg }), onClick: handleRemove, style: styleBtn, isTg: isTg, children: "Delete" })] })] })] }) }) })] }));
};
