import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { TonConnectButton } from '@tonconnect/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/use-media-query';
import { useTonAddress } from '../../hooks/useTonAdress';
import { useTextTelegram } from '../../hooks/useTextTelegram';
import { ROUTES } from '../../utils/router';
import s from './login-page.module.scss';
export const LoginPage = ({ isTg }) => {
    const isMobile = useMediaQuery(768);
    const navigate = useNavigate();
    const location = useLocation();
    const RawAddress = useTonAddress();
    const telegramText = useTextTelegram(isTg);
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryAddress = query.get('a');
        if (queryAddress && RawAddress) {
            navigate(`${ROUTES.ACTIVATE}?a=${queryAddress}`);
        }
        else if (RawAddress) {
            navigate(ROUTES.YOUR_CHECKS);
        }
    }, [RawAddress, location.search, navigate]);
    return (_jsx("section", { children: _jsx("div", { className: `${s.main} ${isMobile ? 'container-mobile' : 'container-pc'}`, children: _jsxs("div", { className: s.mainInner, children: [_jsx("h1", { className: s.mainLogo, style: telegramText, children: "DeCoupons" }), _jsx("div", { className: s.mainButton, children: _jsx(TonConnectButton, {}) })] }) }) }));
};
