export class StorageWallet {
    constructor() {
        this._keyPrefix = 'decoupons-';
        this._data = window.localStorage;
    }
    getFullKey(key) {
        return this._keyPrefix + key;
    }
    save(key, data) {
        try {
            const existingData = this._data.getItem(this.getFullKey(key)) || '[]';
            const dataArray = JSON.parse(existingData);
            dataArray.push(data);
            this._data.setItem(this.getFullKey(key), JSON.stringify(dataArray));
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
    getAllCoupons() {
        const allCoupons = [];
        try {
            for (let i = 0; i < this._data.length; i++) {
                const key = this._data.key(i);
                if (key && key.startsWith(this._keyPrefix)) {
                    const dataArray = this.get(key.replace(this._keyPrefix, ''));
                    if (Array.isArray(dataArray) && dataArray.length > 0 && typeof dataArray[0] === 'object') {
                        allCoupons.push(...dataArray);
                    }
                }
            }
            allCoupons.sort((a, b) => a.date - b.date);
        }
        catch (error) {
            console.error(error);
        }
        return allCoupons;
    }
    get(key) {
        try {
            const dataArray = this._data.getItem(this.getFullKey(key));
            return dataArray ? JSON.parse(dataArray) : null;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
    getByAddress(address) {
        try {
            const dataArray = this.getAllCoupons();
            const result = dataArray.find(item => item.address === address);
            return result || null;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
    del(key) {
        try {
            this._data.removeItem(this.getFullKey(key));
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
}
