import { beginCell, Cell, contractAddress, SendMode } from 'ton-core';
export function multiChequeConfigToCell(config) {
    return beginCell()
        .storeBuffer(config.publicKey)
        .storeCoins(config.chequeAmount)
        .storeAddress(config.ownerAddress)
        .storeUint(config.activaitions, 64)
        .storeUint(BigInt(Math.floor(Math.random() * 1e9)), 32)
        .storeUint(0n, 64)
        .storeRef(config.claimCont)
        .storeRef(config.helperCode)
        .endCell();
}
export const Opcodes = {
    claim: 0x22356c66,
    destroy: 0x7ba45f85
};
export const ClaimFunctions = { toncoin: Cell.fromBoc(Buffer.from('B5EE9C720101010100140000248010C8CB05CE01FA027001CB6AC98040FB00', 'hex'))[0] };
export class MultiCheque {
    constructor(address, init) {
        this.address = address;
        this.init = init;
    }
    static createFromAddress(address) {
        return new MultiCheque(address);
    }
    static createFromConfig(config, code, workchain = 0) {
        const data = multiChequeConfigToCell(config);
        const init = { code, data };
        return new MultiCheque(contractAddress(workchain, init), init);
    }
    async sendDeploy(provider, via, value) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell().endCell()
        });
    }
    async sendClaim(provider, via, value, opts) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell()
                .storeUint(Opcodes.claim, 32)
                .storeBuffer(opts.signature)
                .storeAddress(opts.address)
                .endCell()
        });
    }
    async sendDestroy(provider, via, value) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell().storeUint(Opcodes.destroy, 32).endCell()
        });
    }
    async getUsage(provider) {
        return (await provider.get('get_number_of_uses', [])).stack.readBigNumber();
    }
}
