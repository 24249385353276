// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yJTjsW1s4HBvWiMZJOz0{display:flex;align-items:center;justify-content:space-between;gap:.3125rem;margin-bottom:2.5rem}@media(min-width: 768px){.yJTjsW1s4HBvWiMZJOz0{margin-bottom:3.125rem}}.qTJH0eI0_r9ieZR3JWYi{margin-bottom:3.125rem}@media(min-width: 768px){.qTJH0eI0_r9ieZR3JWYi{margin-bottom:5.6875rem}}.lfkLnkMyimWwnJ3qTsGH{text-align:center;font-size:1.125rem}`, "",{"version":3,"sources":["webpack://./src/pages/settings-page/settings-page.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,YAAA,CACA,oBAAA,CACA,yBANF,sBAOI,sBAAA,CAAA,CAKF,sBACI,sBAAA,CACA,yBAFJ,sBAGQ,uBAAA,CAAA,CAGR,sBACI,iBAAA,CACA,kBAAA","sourcesContent":[".settingsHeader {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: .3125rem;\n  margin-bottom: 2.5rem;\n  @media (min-width: 768px) {\n    margin-bottom: 3.125rem;\n  }\n}\n\n.settings {\n  &Actions {\n      margin-bottom: 3.125rem;\n      @media (min-width: 768px) {\n          margin-bottom: 5.6875rem;\n      }\n  }\n  &Version {\n      text-align: center;\n      font-size: 1.125rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsHeader": `yJTjsW1s4HBvWiMZJOz0`,
	"settingsActions": `qTJH0eI0_r9ieZR3JWYi`,
	"settingsVersion": `lfkLnkMyimWwnJ3qTsGH`
};
export default ___CSS_LOADER_EXPORT___;
