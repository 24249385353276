import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import CHEVRON_DOWN from '../../../assets/images/create-check/chevron-down.svg';
import s from './select.module.scss';
export const Select = ({ options, value, onChange, isTg }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [hasMadeSelection, setHasMadeSelection] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionClick = (optionValue) => {
        onChange(optionValue);
        setIsOpen(false);
        setHasMadeSelection(true);
    };
    useEffect(() => {
        if (hasMadeSelection) {
            setFilteredOptions(options.filter(option => option.value !== value));
        }
        else {
            setFilteredOptions(options.slice(1));
        }
    }, [options, value, hasMadeSelection]);
    const [styles, setStyles] = useState({});
    useEffect(() => {
        if (isTg) {
            setStyles({
                backgroundColor: 'var(--tg-theme-link-color)',
                important: 'true'
            });
        }
        else {
            setStyles({});
        }
    }, [isTg]);
    const styleSelect = { ...styles };
    return (_jsxs("div", { className: s.customSelect, children: [_jsxs("div", { className: s.customSelectActive, onClick: handleToggle, style: styleSelect, children: [value || options[0]?.label, _jsx("img", { src: CHEVRON_DOWN, className: `${s.customSelectChevron} ${isOpen ? s.chevronRotate : s.chevronNoRotate}`, alt: "chevron-down" })] }), isOpen && (_jsx("ul", { className: s.selectList, style: styleSelect, children: filteredOptions.map(option => (_jsx("li", { className: `${s.selectItem} ${option.value === value ? s.active : ''}`, onClick: () => handleOptionClick(option.value), children: option.label }, option.value))) }))] }));
};
