import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TonConnectButton, useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/ui/button';
import { MainTitle } from '../../components/main-title';
import { fixAmount } from '../../utils/fix-amount';
import { ROUTES } from '../../utils/router';
import { useTextTelegram } from '../../hooks/useTextTelegram';
import { useBg2Telegram } from '../../hooks/useBg2Telegram';
import { Coupon } from '../../logic/coupon';
import { StorageWallet } from '../../logic/storage';
import s from './activate-page.module.scss';
export const Activate = ({ address, setAddress, isTestnet, isTg }) => {
    const [psw, setPsw] = useState('');
    const [pswError, setPswError] = useState('');
    const noRamAddres = useTonAddress();
    const [bal, setBal] = useState('0');
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const navigate = useNavigate();
    const location = useLocation();
    const [checkType, setCheckType] = useState('');
    const [usage, setUsage] = useState(0);
    const storageWallet = new StorageWallet();
    const coupon = new Coupon(tonConnectUI, isTestnet);
    async function activateCoupon() {
        if (psw === '') {
            return undefined;
        }
        const ch = new Coupon(tonConnectUI, isTestnet);
        try {
            const ownCoupon = await storageWallet.getByAddress(address);
            let tx;
            if (checkType === 'Personal') {
                tx = await ch.claim(address, noRamAddres.toString(), psw);
            }
            else if (checkType === 'Multicheck') {
                tx = await ch.claimMulti(address, noRamAddres.toString(), psw);
            }
            if (tx) {
                toast.success('Sent for password verification');
                if (ownCoupon) {
                    storageWallet.del(ownCoupon.id);
                }
                navigate(ROUTES.YOUR_CHECKS);
            }
            else {
                toast.error('Incorrect password');
            }
            return tx;
        }
        catch (error) {
            console.log('error', error);
            toast.error('Incorrect password');
            throw error;
        }
    }
    async function fetchUsage() {
        if (!address)
            return;
        try {
            const res = await coupon.getSumActivation(address);
            if (typeof res === 'number') {
                setUsage(res);
                setCheckType('Multicheck');
            }
            else {
                setCheckType('Personal');
            }
        }
        catch (error) {
            console.error('Error fetching usage:', error);
        }
    }
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryAddress = query.get('a');
        if (queryAddress && noRamAddres) {
            navigate(`${ROUTES.ACTIVATE}?a=${queryAddress}`);
            setAddress(queryAddress);
        }
        else if (noRamAddres && address) {
            navigate(ROUTES.ACTIVATE);
            // setAddress(noRamAddres)
        }
        else {
            navigate(ROUTES.YOUR_CHECKS);
        }
        fetchUsage();
    }, [noRamAddres, location.search, address]);
    useEffect(() => () => {
        setAddress('');
    }, []);
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const bl = await Coupon.getSumCoupon(address, isTestnet);
                setBal(bl);
            }
            catch (error) {
                console.error(error);
                setBal('0');
            }
        };
        fetchBalance();
    }, [address, location.search]);
    const handlePasswordChange = (newPassword) => {
        setPsw(newPassword);
        if (newPassword.trim() === '') {
            setPswError('Password cannot be empty');
        }
        else {
            setPswError('');
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (psw.trim() === '') {
            setPswError('Password cannot be empty');
        }
        else if (psw.length < 8) {
            setPswError('Password cannot be less than 8 symbols');
        }
        else {
            setPswError('');
            activateCoupon();
        }
    };
    const telegramText = useTextTelegram(isTg);
    const telegramBG2 = useBg2Telegram(isTg);
    return (_jsxs("section", { children: [_jsxs("div", { className: s.headerForm, children: [_jsx(MainTitle, { title: "Activate", isTg: isTg }), _jsx(TonConnectButton, {})] }), _jsxs("div", { style: telegramText, children: ["Balance coupon: ", fixAmount(bal), " TON"] }), _jsx("br", {}), checkType === 'Multicheck' && (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.item, children: [_jsx("div", { className: s.title, style: telegramText, children: "Number of activations:" }), _jsxs("div", { className: s.description, style: telegramText, children: [usage === 0 ? '0' : usage, " times activated"] })] }), _jsx("br", {})] })), _jsxs("form", { className: s.form, onSubmit: handleSubmit, children: [_jsxs("div", { className: s.formBlock, children: [_jsx("label", { className: s.formLabel, style: telegramText, children: "Password" }), _jsx("input", { type: "password", placeholder: 'password', className: s.formInput, onChange: (e) => handlePasswordChange(e.target.value), value: psw, style: telegramBG2 }), pswError && _jsx("p", { className: s.errorText, children: pswError })] }), _jsx(Button, { variant: 'primary-button', type: "submit", disabled: bal === '0', isTg: isTg, children: "Activate" })] })] }));
};
