import { useEffect, useState } from 'react';
export const useTextTelegram = (isTg) => {
    const [textColor, setTextColor] = useState({});
    useEffect(() => {
        const conditionalTextColor = {};
        if (isTg) {
            conditionalTextColor.color = 'var(--tg-theme-text-color)';
        }
        setTextColor(conditionalTextColor);
    }, [isTg]);
    return textColor;
};
