import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import LinkIcon from '../../assets/images/settings/arrow-link.svg';
import s from './settings-link.module.scss';
export const Link = ({ icon, iconAlt, text, href, isTg }) => {
    const [style, setStyle] = useState({});
    useEffect(() => {
        if (isTg) {
            setStyle({
                backgroundColor: 'var(--tg-theme-link-color)',
                important: 'true'
            });
        }
        else {
            setStyle({});
        }
    }, [isTg]);
    return (_jsxs("a", { href: href, target: "_blank", className: s.link, style: style, children: [_jsxs("div", { className: s.linkBody, children: [icon && _jsx("img", { src: icon, className: s.linkImg, alt: iconAlt }), _jsx("span", { className: s.linkTitle, children: text })] }), _jsx("img", { src: LinkIcon, alt: "link icon" })] }));
};
