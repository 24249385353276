// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CuTy41NhLxkgz9UMO2S9{min-height:100vh;padding:25px 20px 0 20px}@media(max-width: 600px){.CuTy41NhLxkgz9UMO2S9{padding:20px 20px 0 20px}}.UFAkHrb8kQa4SHqzAkkw{overflow:hidden;padding-bottom:7.3rem}@media(min-width: 768px){.UFAkHrb8kQa4SHqzAkkw{padding:30px 0 7.3rem 0}}@media(max-height: 650px){.UFAkHrb8kQa4SHqzAkkw{padding-bottom:6.5rem}}.qgPG6WwjGp8gb3y5aqe6{position:fixed;left:50%;bottom:.9375rem;transform:translateX(-50%);z-index:10;width:21.5625rem}@media(min-width: 768px){.qgPG6WwjGp8gb3y5aqe6{width:auto}}@media(max-width: 360px){.qgPG6WwjGp8gb3y5aqe6{width:19.5625rem}}`, "",{"version":3,"sources":["webpack://./src/layout/layout.module.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CACA,wBAAA,CACA,yBAHJ,sBAIQ,wBAAA,CAAA,CAIR,sBACI,eAAA,CACA,qBAAA,CACA,yBAHJ,sBAIQ,uBAAA,CAAA,CAEJ,0BANJ,sBAOQ,qBAAA,CAAA,CAIR,sBACI,cAAA,CACA,QAAA,CACA,eAAA,CACA,0BAAA,CACA,UAAA,CACA,gBAAA,CACA,yBAPJ,sBAQQ,UAAA,CAAA,CAEJ,yBAVJ,sBAWQ,gBAAA,CAAA","sourcesContent":[".main {\n    min-height: 100vh;\n    padding: 25px 20px 0 20px;\n    @media (max-width: 600px) {\n        padding: 20px 20px 0 20px;\n    }\n}\n\n.content {\n    overflow: hidden;\n    padding-bottom: 7.3rem;\n    @media (min-width: 768px) {\n        padding: 30px 0 7.3rem 0;\n    }\n    @media (max-height: 650px) {\n        padding-bottom: 6.5rem;\n    }\n}\n\n.actions {\n    position: fixed;\n    left: 50%;\n    bottom: 0.9375rem;\n    transform: translateX(-50%);\n    z-index: 10;\n    width: 21.5625rem;\n    @media (min-width: 768px) {\n        width: auto;\n    }\n    @media (max-width: 360px) {\n        width: 19.5625rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CuTy41NhLxkgz9UMO2S9`,
	"content": `UFAkHrb8kQa4SHqzAkkw`,
	"actions": `qgPG6WwjGp8gb3y5aqe6`
};
export default ___CSS_LOADER_EXPORT___;
