import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
// import eruda from 'eruda'
import { App } from './App';
import './index.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));
// const el = document.createElement('div')
// document.body.appendChild(el)
// eruda.init({
//     container: el,
//     tool: [ 'console', 'elements' ]
// })
root.render(_jsx(TonConnectUIProvider, { manifestUrl: "https://gist.github.com/anovic123/80cd4f1a001af87d0d7463e9abd00db8.txt", children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }));
