// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PTYO8K3Qi6vNZmpayy_J>h1{font-size:1.75rem;font-weight:600}@media(max-width: 768px){.PTYO8K3Qi6vNZmpayy_J>h1{font-size:24px}}`, "",{"version":3,"sources":["webpack://./src/components/main-title/main-title.module.scss"],"names":[],"mappings":"AACE,yBACE,iBAAA,CACA,eAAA,CACA,yBAHF,yBAII,cAAA,CAAA","sourcesContent":[".mainTitle {\n  > h1 {\n    font-size: 1.75rem;\n    font-weight: 600;\n    @media(max-width: 768px) {\n      font-size: 24px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainTitle": `PTYO8K3Qi6vNZmpayy_J`
};
export default ___CSS_LOADER_EXPORT___;
