import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Address, TonClient } from 'ton';
import { useTonAddress } from '@tonconnect/ui-react';
import { ToastContainer } from 'react-toastify';
import { YourChecksPage } from './pages/your-checks-page';
import { SettingsPage } from './pages/settings-page';
import { CreateCheckPage } from './pages/create-check-page';
import { QrScannerPage } from './pages/qr-scanner-page';
import { LoginPage } from './pages/login-page';
import { Activate } from './pages/activate';
import { Layout } from './layout';
import { ROUTES } from './utils/router';
import { PrivateRoute } from './utils/privateRouter';
import 'react-toastify/dist/ReactToastify.css';
const isTestnet = window.location.host.indexOf('localhost') >= 0
    ? true
    : window.location.href.indexOf('testnet') >= 0;
export const App = () => {
    const [firstRender, setFirstRender] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [balance, setBalance] = useState(undefined);
    const [isTg, setIsTg] = useState(false);
    const [tonClient, setTonClient] = useState(new TonClient({
        endpoint: isTestnet
            ? 'https://testnet.tonhubapi.com/jsonRPC'
            : 'https://mainnet.tonhubapi.com/jsonRPC'
    }));
    const [addressCoupon, setAddressCoupon] = useState('');
    const RawAddress = useTonAddress();
    useEffect(() => {
        const isTgCheck = window.Telegram.WebApp.initData !== '';
        const TgObj = window.Telegram.WebApp;
        const bodyStyle = document.body.style;
        setIsTg(isTgCheck);
        if (isTgCheck) {
            TgObj.ready();
            TgObj.enableClosingConfirmation();
            TgObj.expand();
            bodyStyle.backgroundColor = 'var(--tg-theme-secondary-bg-color)';
            bodyStyle.setProperty('background-color', 'var(--tg-theme-secondary-bg-color)', 'important');
        }
    }, [firstRender]);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    useEffect(() => {
        if (RawAddress) {
            tonClient.getBalance(Address.parse(RawAddress)).then((bl) => {
                setBalance(bl.toString());
            });
        }
    }, [RawAddress]);
    return (_jsxs(_Fragment, { children: [_jsxs(Routes, { children: [_jsx(Route, { element: _jsx(PrivateRoute, {}), children: _jsxs(Route, { element: _jsx(Layout, { isTg: isTg }), children: [_jsx(Route, { path: ROUTES.YOUR_CHECKS, element: _jsx(YourChecksPage, { isTestnet: isTestnet, isTg: isTg }) }), _jsx(Route, { path: ROUTES.CREATE_CHECK, element: _jsx(CreateCheckPage, { balance: balance, isTestnet: isTestnet, isTg: isTg }) }), _jsx(Route, { path: ROUTES.QR_SCANNER, element: _jsx(QrScannerPage, { setAddress: setAddressCoupon, address: addressCoupon, isTg: isTg }) }), _jsx(Route, { path: ROUTES.SETTINGS, element: _jsx(SettingsPage, { isConnected: isConnected, balance: balance, isTestnet: isTestnet, isTg: isTg }) }), _jsx(Route, { path: ROUTES.ACTIVATE, element: _jsx(Activate, { isTg: isTg, balance: balance, isTestnet: isTestnet, address: addressCoupon, setAddress: setAddressCoupon }) })] }) }), _jsx(Route, { path: ROUTES.LOGIN, element: _jsx(LoginPage, { isTg: isTg }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: '/', replace: true }) })] }), _jsx(ToastContainer, { position: "top-right", autoClose: 5000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true })] }));
};
